import { Component } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { TenantService } from '../../../pages/tenant/tenant.service';

declare var window: any;

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <span class="created-by">{{domain_footer}}</span>
  <!--
  <div class="socials">
    <a href="https://github.com/ictinnovations/ictfax" target="_blank" class="ion ion-social-github"></a>
    <a href="https://www.facebook.com/ICTInnovations/" target="_blank" class="ion ion-social-facebook"></a>
    <a href="https://twitter.com/tahiralmas" target="_blank" class="ion ion-social-twitter"></a>
    <a href="https://pk.linkedin.com/company/ict-innovations" target="_blank" class="ion ion-social-linkedin"></a>
  </div>
  !-->
  `,
})
export class FooterComponent {

  auser: any;
  
  domain_footer: string = 'Developed by Fiza Khan Copyright ICT Innovations 2020, all right reserved';

  constructor(private authService: NbAuthService, private tenant_service: TenantService) {

    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {
      this.auser = token.getPayload();
      this.get_domain_footer();
    });
  }

  get_domain_footer() { 
    this.tenant_service.get_configuration(this.auser.user_id, 'site:footer').then(response => {
      this.domain_footer = response;
    });
  }

}


