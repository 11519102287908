import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TenantService } from '../../../pages/tenant/tenant.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AppService } from '../../../app.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  private subscription: Subscription //import from rxjs 

  domain_title: string = 'ICTFax'; 

  auser: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: this.translate.instant('general.change_pass'), link: '/pages/Changepass' }, { title: this.translate.instant('general.log_out'), link: 'auth/logout' } ];

  image_url = "../../../../assets/images/ictfax-logo.png";

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService, private authService: NbAuthService,
              private router: Router,private nbMenuService: NbMenuService,
              private tenant_service: TenantService, public translate: TranslateService, private app_service: AppService) {

                this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          this.auser = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
          localStorage.setItem('username', this.auser.username)
          localStorage.setItem('is_admin', this.auser.is_admin);
          // console.log(this.auser);
          this.get_domain_title();

          this.image_url = `${this.app_service.apiUrlUsers}/${this.auser.user_id}/media`; 

        }

      });
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

      this.subscription = this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if (title == 'Log out') {
        let copy_token = localStorage.getItem('copy_token');
        if (copy_token != null) {
          localStorage.removeItem('copy_token');
    
          this.authService.getToken()
          .subscribe((token: NbAuthJWTToken,
          ) => {
            if (token && token.getValue()) {
              let auser = token.getPayload();
              localStorage.setItem('username', auser.username);
              localStorage.setItem('is_admin', auser.is_admin);
            }
          });
    
          this.router.navigate(['/dashboard']);
    
        }
        else {
          this.router.navigate(['auth/logout']);
        }
        }
      });
      
  
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscription.unsubscribe();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  ngDoCheck() {
    let myname = localStorage.getItem('username');
    if (myname != undefined ) {
      this.auser.username = myname;
    }
  }  
    
  get_domain_title() { 
    this.tenant_service.get_configuration(this.auser.user_id, 'site:title').then(response => {
      this.domain_title = response;
    });
  }
}
